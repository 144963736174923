.ant-select{
    font-size: 16px !important;
    .ant-select-selector{
        font-size: 16px !important;
        padding: 0 15px !important;
        background-color: #00000080 !important;
        color: #F4F4F4CC !important;
        border-radius: 18px !important;
        border: 1px solid #D597FF !important;
    }
}
.ant-select-open{
    color: #F4F4F4CC !important;
}
.ant-select-selection-item{
    color: #F4F4F4CC !important;
}
.ant-select-arrow{
    color: #F4F4F4CC !important;
}
.prism-ErrorMessage{
    border-radius: 18px !important;
}

.live-room-container {
    min-width: 1600px;
    overflow-x: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    margin: 0 auto;
    margin-top: 25px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    .select-container {
      width: 1440px;
      margin-bottom: 19px;
      display: flex;
      justify-content: space-between;

      .select-wrapper {
        display: flex;
        .agentTiltle{
          font-size: 18px;
        }
      }
      .toLink{
        border: 1px solid #D597FF;
        border-radius: 10px;
        padding: 8px 25px;
        cursor: pointer;
        display: flex;
        margin: 0 10px 0 0;
      }
      .program-list{
        border: 1px solid #D597FF;
        border-radius: 10px;
        padding: 8px 25px;
        cursor: pointer;
        display: flex;
      }
    }
  
    .main-content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 1440px;
      padding-top: 47px;
      padding-bottom: 47px;
      padding-left: 19px;
      padding-right: 19px;
      border-radius: 18px;
      border: 1px solid #D597FF;
      background-color: #00000080;
  
      .video-and-chat {
        display: flex;
        width: 100%;
  
        .video-container {
          display: flex;
          width: 1008px;
          height: 567px;
          border-radius: 18px;
          border: 1px solid #D597FF;
          margin-right: 10px;
        }
        .live-cover{
          width: 100%;
          height: 100%;
          border-radius: 18px;
          img{
            width: 100%;
            height: 100%;
            border-radius: 18px;
          }
        }
  
        .chat-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 382px;
          height: 567px;
          justify-content: center;
          align-items: center;
          border-radius: 18px;
          border: 1px solid #D597FF;
          background-color: #0000004D;
  
          .chat-title {
            width: 326px;
            display: flex;
            flex: 0;
            justify-content: center;
            align-items: center;
  
            span {
              &:first-child,
              &:last-child {
                flex: 1;
                border: 0.5px solid #F4F4F4CC;
              }
  
              &:nth-child(2) {
                margin: 5px;
                color: #F4F4F4CC;
              }
            }
          }
  
          .chat-messages {
            display: flex;
            flex-direction: column-reverse;
            align-items: end;
            align-content: end;
            flex: 1;
            width: 100%;
            padding-left: 23px;
            padding-right: 10px;
            overflow-y: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
  
            &::-webkit-scrollbar {
              display: none;
            }
  
            .message-item {
              width: 100%;
              display: flex;
              margin-bottom: 15px;
  
              .message-avatar {
                min-width: 38px;
                min-height: 38px;
                margin-right: 8px;
  
                img {
                  width: 38px;
                  height: 38px;
                  border-radius: 50%;
                  border: 1px solid #D597FFCC;
                }
              }
  
              .message-text {
                display: flex;
                align-items: center;
                border-radius: 7px;
                background-color: #39393980;
                font-size: 14px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 12px;
                padding-right: 12px;
                color: #F4F4F4;
                word-wrap: break-word;
                word-break: break-all;
  
                &.sent {
                  color: #F642D7;
                }
              }
            }
          }
          .chat-input-noLogin{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 358px;
            height: 48px;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 21px;
            margin-bottom: 18px;
            border: 1px solid #D597FFCC;
            border-radius: 9px;
            background-color: #39393980;
            .loginTips{
              font-size: 14px;
            }
            .toLogin{
              color: #D597FF;
              border: 1px solid #D597FFCC;
              border-radius: 9px;
              padding: 1px 10px;
              position: absolute;
              right: 10px;
            }
          }
          .chat-input {
            position: relative;
            display: flex;
            align-items: center;
            width: 358px;
            height: 38px;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 21px;
            margin-bottom: 18px;
            border: 1px solid #D597FFCC;
            border-radius: 9px;
            background-color: #39393980;
  
            input {
              flex: 1;
              height: 100%;
              font-size: 14px;
              font-family: AudiowideRegular;
              background-color: transparent !important;
              border: none !important;
              outline: none !important;
              color: #F4F4F4;
            }
            input::placeholder {
                color: #999797;
            }
  
            .send-button {
              cursor: pointer;
  
              img {
                width: 18px;
                height: 18px;
              }
            }
  
            .countdown {
              margin-left: 2px;
              color: white;
            }
  
            .select-box {
              position: absolute;
              bottom: 100%;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: start;
              border-radius: 7px;
              background-color: #39393980;
              font-size: 14px;
              padding-top: 7px;
              padding-bottom: 7px;
              padding-left: 12px;
              padding-right: 12px;
              cursor: pointer;
  
              .select-item {
                display: flex;
              }
            }
          }
        }
      }

      .bomAgentTips{
        position: absolute;
        bottom: 10px;
        right: 19px;
        color: #999797;
      }

    }

    

  }

  .ant-modal{
    width: 80% !important;
    height: 80%;
    .ant-modal-content{
      display: flex;
      flex-direction: column;
      height: 100%;
      background: #170245;
      color: #F4F4F4;
      .ant-modal-close{
        .ant-modal-close-x{
          color: #F4F4F4 !important;
        }
      }
      .ant-modal-header{
        background: #170245 !important;
        border: 0px;
        .ant-modal-title{
          color: #F4F4F4 !important;
          text-align: center;
          font-weight: bold;
          font-size: 18px;
        }
      }
      .ant-modal-body{
        flex: 1;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
    }
  } 

  .program-box{
    width: 100%;
    height: 100%;
    
    table{
      width: 100%;
      height: 100%;
      font-size: 14px;
      .th{
        width: 150px;
        font-size: 16px;
      }
      .thMusic{
        width: 300px;
        font-size: 16px;
      }
      td{
        padding: 10px 0;
      }
    }
  }
  table tr td:nth-child(-n + 4) {
    text-align: center;
  }

.agentLiveRoom-right{
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  .toAiImg{
    width: 100%;
    height: 100%;
    padding: 0 0  0 15px;
    cursor: pointer;
  }
}  
@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;