#liveRoom{
    width:100%;
    height:100%;
    background-color: transparent;
    position: relative;
    video{
        object-fit: cover;
        border-radius: 18px !important;
        width: 100% !important;        
        height: 100% !important;
    }
}
.prism-text-overlay{
    display: none !important;
} 
.prism-auto-stream-selector{
    display: none !important;
}
@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;